import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';
import axios from 'axios';

interface ExportEmailsModalProps {
    open: boolean;
    handleClose: () => void;
}

export default function ExportEmailsModal({
    open,
    handleClose,
}: ExportEmailsModalProps) {
    const [date, setDate] = useState('');

    const handleExport = async () => {
        try {
            const response = await axios.get(`/clients/export/emails?date=${date}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `emails_export_${date}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            handleClose();
        } catch (error) {
            console.error('Error al exportar emails:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Exportar Emails</DialogTitle>
            <DialogContent>
                <TextField
                    label="Fecha desde"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleExport} variant="contained" color="primary">
                    Exportar
                </Button>
            </DialogActions>
        </Dialog>
    );
} 