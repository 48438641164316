import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router';
import axios from 'axios';
import {
	CalendarTodayOutlined,
	ChildCareOutlined,
	Groups2Outlined,
	LocationOnOutlined,
	RemoveRedEyeOutlined,
} from '@mui/icons-material';
import MamamHeader from '../../common/components/MamamH3';
import { Member } from '../../common/entities/Member';
import ClientHistoryDisplay from '../../common/components/ClientHistory';
import { ClientHistory } from '../../common/entities/ClientHistory';
import ContactContainer from '../../common/components/ContactContainer';
import Pill from '../../common/components/Pill';
import { Color } from '../../common/enums/pill-colors';
import { dateFormater } from '../../common/utils';
import InfoContainer from '../../common/components/InfoContainer';
import CustomSeparator from '../../common/components/BreadCrumbs';
import { UserRole } from '../../common/enums/user-role.enum';
import { useUser } from '../../common/contexts/userContext';

const Cards = styled('div')(() => ({
	display: 'flex',
	'@media(max-width:700px)': {
		flexDirection: 'column',
	},
}));

const Container = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	width: '100%',
}));
export default function MemberDetails() {
	const [client, setClient] = useState<Partial<Member>>();
	const [clientHistory, setClientHistory] = useState<ClientHistory[]>([]);
	const user = useUser();
	const { id } = useParams();
	const location = useLocation();
	console.log(location);

	useEffect(() => {
		axios
			.request({ method: 'GET', url: `/clients/${id}/details` })
			.then((response) => {
				setClient(response.data);
			})
			.catch((error) => console.error(error));

		axios
			.request({ method: 'GET', url: `/client-history/client/${id}` })
			.then((response) => {
				setClientHistory(response.data);
			})
			.catch((error) => console.error(error));

		return setClient({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{ name: `Detalle de ${client?.name || 'miembro'}`, path: '' },
	];

	user?.role === UserRole.ADMIN
		? breadcrumbs.unshift({ name: 'Miembros', path: '/miembros' })
		: breadcrumbs.unshift({
				name: 'Grupo',
				path: `/grupos/${client?.group?.id}`,
		  });
	return (
		<div>
			<CustomSeparator crumbs={breadcrumbs} />

			<MamamHeader text={`Detalle de ${client?.name || 'miembro'}`} />
			<hr
				style={{ backgroundColor: 'rgb(211, 218, 230)', marginBlock: '16px' }}
			></hr>
			<Container>
				<Cards>
					<div style={{ display: 'flex' }}>
						{client?.state && (
							<InfoContainer width="10rem" title="Estado" little>
								<Pill
									color={client?.state.label_color as Color}
									text={client?.state?.name}
								/>
							</InfoContainer>
						)}
						<InfoContainer
							width="10rem"
							title="Primer contacto"
							icon={<CalendarTodayOutlined />}
							little
						>
							<p style={{ margin: 0 }}>
								{client?.first_contact && dateFormater(client?.first_contact)}
							</p>
						</InfoContainer>
					</div>

					<InfoContainer width="20rem" title="Grupo" icon={<Groups2Outlined />}>
						<p style={{ margin: 0 }}>{client?.group?.name}</p>
					</InfoContainer>
					<InfoContainer
						width="11rem"
						title="Embarazada / hijos"
						icon={<ChildCareOutlined />}
					>
						<p style={{ margin: 0 }}>{client?.pregnant_childs || '---'}</p>
					</InfoContainer>
				</Cards>
				<Cards>
					<InfoContainer
						width="45.3rem"
						title="Observaciones"
						icon={<RemoveRedEyeOutlined />}
						align="left"
					>
						<p
							style={{
								margin: 0,
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
								whiteSpace: 'pre-wrap',
							}}
						>
							{client?.observations || '---'}
						</p>
					</InfoContainer>
					<InfoContainer
						width="11rem"
						title="Zona de interes"
						icon={<LocationOnOutlined />}
					>
						<p style={{ margin: 0 }}>{client?.prefered_zone || '---'}</p>
					</InfoContainer>
				</Cards>

				<Cards>
					<div>
						<ContactContainer
							width="400px"
							facebook={client?.facebook}
							instagram={client?.instagram}
							email={client?.email}
							phone={client?.phone}
							contactMethod={client?.contact_method}
						/>
					</div>
					<ClientHistoryDisplay histories={clientHistory} />
				</Cards>
			</Container>
		</div>
	);
}
