import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { Member } from '../../common/entities/Member';
import { BaseResponse } from '../../common/interfaces/base-response';
import MemberFormModal from '../../common/components/FormModals/MemberFormModal';
import axios from 'axios';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import { dateFormater } from '../../common/utils';
import CustomSeparator from '../../common/components/BreadCrumbs';
import MamamHeader from '../../common/components/MamamH3';
import { Link } from 'react-router-dom';
import Pill from '../../common/components/Pill';
import { Color } from '../../common/enums/pill-colors';
export default function Interested() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [interested, setInterested] = useState<BaseResponse<Member>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		axios
			.request({
				method: 'GET',
				url: `/interesteds?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setInterested(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		interested?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: `${interested?.count || 0}`,
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.INTERESTED}
						is_active={params.row.is_active}
						was_seen={params.row.was_seen}
					/>
				);
			},
		},
		{
			field: 'first_contact',
			headerName: 'Primer contacto',
			width: 130,
			valueFormatter(params) {
				return dateFormater(params.value);
			},
		},
		{ field: 'contact_method', headerName: 'Metodo de contacto', width: 170 },
		{ field: 'name', headerName: 'Nombre', width: 150 },
		{ field: 'prefered_zone', headerName: 'Zona de interes', width: 180 },
		{ field: 'instagram', headerName: 'Instagram', width: 200 },
		{ field: 'phone', headerName: 'Teléfono', width: 200 },
		{ field: 'email', headerName: 'Email', width: 200 },
		{
			field: 'chosenGroup',
			headerName: 'Grupo elegido',
			width: 300,
			renderCell(params) {
				if (!params.value?.name) {
					return '---';
				}
				return (
					<Link to={`/grupos/${params.value.id}`}>
						<Pill text={params.value.name} color={Color.Blue} />
					</Link>
				);
			},
		},
	];

	return (
		<div>
			{open && (
				<MemberFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Inscripción persona interesada"
					parentType={ItemType.INTERESTED}
				/>
			)}
			<CustomSeparator
				crumbs={[{ name: 'Interesadas', path: '/interesadas' }]}
			/>
			<MamamHeader text="Interesadas" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading}
				onPageChange={handlePageChange}
				rowCount={interested?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.INTERESTED}
			></Table>
		</div>
	);
}
